export { AboutIcon } from "./about-icon";
export { SupportIcon } from "./support-icon";
export { AppDevelopmentIcon } from "./app-development-icon";
export { BlogIcon } from "./blog-icon";
export { CertificationIcon } from "./certification-icon";
export { CustomersStoryIcon } from "./customers-story-icon";
export { WhatsNewIcon } from "./whats-new-icon";
export { HelpCenterIcon } from "./help-center-icon";
export { PartnersIcon } from "./partners-icon";
export { TemplateCenterIcon } from "./template-center-icon";
