import styled from "@emotion/styled";
export const StyledProductsMenuComponent = styled.div`
  .platform-section {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .links-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 256px;
    }
  }

  .products-section {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .products-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 12px;
      margin-right: 40px;

      .descriptive-link-block {
        width: 296px;
      }
    }
  }
`;
