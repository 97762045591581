import styled from "@emotion/styled";
import colors from "styles/colors";
import { BACKGROUND_GRAY_COLOR_NAME, BRAND_BLACK_TEXT_COLOR, WHITE_COLOR_NAME } from "styles/color-consts";
import Constants from "segments/desktop/header/layouts/components/basic-header/constants";
import Colors from "styles/colors.js";
import { BRAND_SERVICE_PRIMARY_COLOR_NAME, LIGHT_BACKGROUND_PINK } from "styles/color-consts";
const SEPARATOR_LINE_COLOR = "#d9d9d9";
export const StyledProductsSwitcher = styled.div`
  height: ${Constants.PRODUCTS_SWITCHER_NEW_USERS_SCREEN_HEIGHT}px;
  width: ${Constants.PRODUCTS_SWITCHER_SCREEN_WIDTH}px;
  border-radius: 8px;
  background-color: ${colors[WHITE_COLOR_NAME]};
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.2);
  padding: 24px;

  &.is-existing {
    height: ${Constants.PRODUCTS_SWITCHER_EXISTING_USERS_SCREEN_HEIGHT}px;
  }

  .main-products-title-wrapper {
    margin-bottom: 16px;

    .main-product-title {
      font-size: 0.875rem;
      color: ${colors[BRAND_BLACK_TEXT_COLOR]};
      font-weight: 400;
    }
  }

  .main-products-wrapper {
    display: flex;
    flex-direction: column;
  }

  .separator-line {
    margin: 16px 0 24px 0;
    height: 1px;
    background-color: ${SEPARATOR_LINE_COLOR};
  }

  .additional-products-title {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${colors.asphalt};
    margin-bottom: 16px;
  }

  .additional-products-wrapper {
    display: flex;
    flex-direction: row;

    .product {
      .icon-wrapper {
        .picture-component {
          display: flex;
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .product {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 400;
    color: ${colors.asphalt};
    padding: 8px;
    border-radius: 8px;
    text-decoration: none;

    .icon-wrapper {
      .picture-component {
        display: flex;
        height: 32px;
        width: 32px;
      }
    }

    .beta-tag {
      font-size: 0.6875rem;
      font-weight: 300;
      color: ${Colors[BRAND_SERVICE_PRIMARY_COLOR_NAME]};
      background-color: ${Colors[LIGHT_BACKGROUND_PINK]};
      border-radius: 2px;
      padding: 1px 5px;
      line-height: normal;
      border-radius: 32px;
    }

    :hover {
      background-color: ${colors[BACKGROUND_GRAY_COLOR_NAME]};
    }
  }
`;
