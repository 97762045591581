import { useState, useContext } from "react";
import { GenericConfigContext } from "contexts/generic-config-context";
import { shouldUseNewTypeformVariant } from "services/ab-tests/acquisition-ab-test-service";
import { shouldGetTypeformExperience } from "services/typeform/typeform-service";
import { openContactSalesTypeform, TYPEFORM_DESKTOP_DEVICE_TYPE } from "/constants/typeform-contact-sales";
import { handleClientException } from "client/services/exceptions-handling-service/exceptions-handling-service";
import { TYPEFORM_UNHANDLED_EXCEPTION } from "/constants/bigbrain-event-types";
export function useContactSalesTypeform({ abTests, fallbackLink }) {
    const [isShowingTypeform, setIsShowingTypeform] = useState(false);
    const { forcePricing, useNewTypeformUSVariant, cookies, forceCurrency, cloudfrontViewerCountry, isV13PricingVersion, testTypeformContactSales, forceTypeformContactSalesExperience, contactSalesTestMode } = useContext(GenericConfigContext);
    const onCallback = ()=>{
        setIsShowingTypeform(false);
    };
    const displayTypeform = ()=>{
        const getNewVariantForTest = useNewTypeformUSVariant || shouldUseNewTypeformVariant(abTests, true);
        const pricingData = {
            forcePricing,
            cookies,
            abTests,
            forceCurrency,
            countryCode: cloudfrontViewerCountry,
            isV13PricingVersion
        };
        if (shouldGetTypeformExperience({
            abTests,
            cloudfrontViewerCountry,
            testTypeformContactSales,
            forceTypeformContactSalesExperience,
            sendBigBrainEvent: true
        })) {
            try {
                setIsShowingTypeform(true);
                openContactSalesTypeform({
                    country: cloudfrontViewerCountry,
                    deviceType: TYPEFORM_DESKTOP_DEVICE_TYPE,
                    getNewVariantForTest,
                    isTest: contactSalesTestMode,
                    pricingData,
                    onCloseCallback: onCallback,
                    onSubmitCallback: onCallback,
                    onReadyCallback: onCallback
                });
            } catch (e) {
                const bbEventParams = {
                    name: TYPEFORM_UNHANDLED_EXCEPTION
                };
                handleClientException({
                    e,
                    bbEventParams
                });
                window.location = fallbackLink;
            }
        }
    };
    return {
        isShowingTypeform,
        displayTypeform
    };
}
