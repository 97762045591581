import styled from "@emotion/styled";
import colors from "/styles/colors";
import { SIDE_NAV } from "constants/z-index";
export const StyledHamburgerMenuComponent = styled.div`
  width: 28px;
  transform: rotate(0deg);
  cursor: pointer;
  z-index: ${SIDE_NAV + 1};
  margin-left: 24px;
  margin-right: 8px;
  height: 36px;
  display: flex;

  .hamburger-menu-line {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: ${colors["black"]};
    border-radius: 4px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;

    &:nth-child(1) {
      top: 8px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 17px;
    }

    &:nth-child(4) {
      top: 26px;
    }
  }

  &.open {
    .hamburger-menu-line {
      background-color: ${colors["black"]};

      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 18px;
        width: 0;
        left: 50%;
      }
    }
  }
`;
