import { AboutIcon, AppDevelopmentIcon, BlogIcon, CertificationIcon, CustomersStoryIcon, HelpCenterIcon, PartnersIcon, SupportIcon, TemplateCenterIcon, WhatsNewIcon } from "./icons/index.js";
import { ABOUT_US_PAGE_LINK, APPS_DEVELOPER_LINK, BLOG_PAGE_LINK, BUILDERS_CERTIFICATIONS_PAGE_LINK, CUSTOMERS_PAGE_LINK, HELP_CENTER_LINK, PARTNERS_PAGE_LINK, SUPPORT_PAGE_LINK, TEMPLATE_CENTER_PAGE_LINK, WHATS_NEW_PAGE_LINK } from "constants/links";
export const LEARN_LINKS = [
    {
        Icon: AboutIcon,
        text: "header.tabs.resources.aboutUs",
        link: ABOUT_US_PAGE_LINK
    },
    {
        Icon: WhatsNewIcon,
        text: "header.tabs.resources.whatsNew",
        link: WHATS_NEW_PAGE_LINK
    },
    {
        Icon: BlogIcon,
        text: "header.tabs.resources.blog",
        link: BLOG_PAGE_LINK
    },
    {
        Icon: CertificationIcon,
        text: "header.tabs.resources.getCertified",
        link: BUILDERS_CERTIFICATIONS_PAGE_LINK
    },
    {
        Icon: CustomersStoryIcon,
        text: "header.tabs.resources.customerStories",
        link: CUSTOMERS_PAGE_LINK
    }
];
export const SUPPORT_LINKS = [
    {
        Icon: HelpCenterIcon,
        text: "header.tabs.resources.helpCenter",
        link: HELP_CENTER_LINK
    },
    {
        Icon: SupportIcon,
        text: "header.tabs.resources.support",
        link: SUPPORT_PAGE_LINK
    },
    {
        Icon: PartnersIcon,
        text: "header.tabs.resources.partners",
        link: PARTNERS_PAGE_LINK
    }
];
export const BUILD_LINKS = [
    {
        Icon: TemplateCenterIcon,
        text: "header.tabs.resources.templateCenter",
        link: TEMPLATE_CENTER_PAGE_LINK
    },
    {
        Icon: AppDevelopmentIcon,
        text: "header.tabs.resources.appDevelopment",
        link: APPS_DEVELOPER_LINK
    }
];
