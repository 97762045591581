import styled from "@emotion/styled";
export const StyledDownloadMobileAppComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .download-mobile-app-image {
    width: 168px;
  }
`;
