import styled from "@emotion/styled";
import { HEADER_DESKTOP_MENU_MIN_WIDTH } from "/constants/vp-sizes";
export const StyledBaseHeaderComponent = styled.div`
  .desktop-wrapper {
    display: block;
  }

  .mobile-wrapper {
    display: none;
  }

  @media (max-width: ${HEADER_DESKTOP_MENU_MIN_WIDTH}) {
    .desktop-wrapper {
      display: none;
    }

    .mobile-wrapper {
      display: block;
    }
  }
`;
