import styled from "@emotion/styled";
import colors from "styles/colors";
import { BACKGROUND_GRAY_COLOR_NAME } from "styles/color-consts";
export const StyledStructuredHeader = styled.nav`
  .products-switcher-open-button {
    cursor: pointer;
    margin-left: 16px;
    border-radius: 4px;

    .picture-component {
      display: flex;
      height: 40px;
      width: 40px;
    }

    &.show-switcher {
      background-color: ${colors[BACKGROUND_GRAY_COLOR_NAME]};
    }

    :hover {
      background-color: ${colors[BACKGROUND_GRAY_COLOR_NAME]};
    }
  }

  .menu-component-wrapper.short-menu {
    margin: auto;
    width: fit-content;
  }
`;
