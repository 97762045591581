import styled from "@emotion/styled";
import Colors from "styles/colors";
import { GAINSBORO_COLOR_NAME } from "styles/color-consts";
export const StyledMenuDivider = styled.div`
  width: 1px;
  background-color: ${Colors[GAINSBORO_COLOR_NAME]};
  margin: 0;
	
  &.horizontal {
    height: 1px;
    width: 100%;
  }
`;
