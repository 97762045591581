import styled from "@emotion/styled";
import Colors from "styles/colors";
export const StyledBadgeComponent = styled.span`
  padding: 1px 8px 2px 7px;
  border-radius: 2px;
  background-color: ${Colors["brand-yellow"]};
  font-size: 0.6875rem;
  color: ${Colors.black};

  &.has-stroke {
    padding-top: 0px;
    padding-bottom: 1px;
  }
`;
