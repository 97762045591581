import styled from "@emotion/styled";
export const StyledSolutionsMenuComponent = styled.div`
  .section {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .links {
      display: flex;
      flex-direction: column;
      gap: 8px;
	    
	    &.company-links {
		    gap: 24px;
	    }

      .descriptive-link-block {
	      width: 256px;
	      
        .details {
          max-width: 164px;
        }
      }

      .mini-link-item {
        width: 160px;
	      height: 20px;
      }
    }

    .teams-links-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 32px;
      grid-row-gap: 8px;
    }
  }
`;
