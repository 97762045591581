import styled from "@emotion/styled";
import Colors from "styles/colors";
import { BACKGROUND_GRAY_COLOR_NAME, BRAND_WORK_MANAGEMENT_PRIMARY_COLOR } from "styles/color-consts";
export const StyledMiniLinkItem = styled.a`
  all: unset;
  cursor: pointer;
  display: flex;
  gap: 16px;
  padding: 8px;
  font-weight: 400;
  font-size: 0.875rem;
  align-items: center;
  border-radius: 8px;
  transition: all 0.2s ease-in;

  .icon-container {
    display: flex;
  }

  &:hover {
    background-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
    color: ${Colors[BRAND_WORK_MANAGEMENT_PRIMARY_COLOR]};
  }
`;
