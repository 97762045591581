import styled from "@emotion/styled";
import Colors from "styles/colors";
export const StyledHeaderMenuComponent = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${Colors["white"]};
  user-select: none;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  padding: 0px 32px 80px;
  overflow: auto;

  .download-mobile-app-component-wrapper {
    margin-top: 32px;
  }

  .monday-link-wrapper {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 28px;

    .picture-component {
      img {
        max-height: 20px;
        vertical-align: bottom;
      }
    }

    .monday-link-text-wrapper {
      justify-content: center;
      vertical-align: middle;

      .secondary-button {
        line-height: 24px;

        a {
          &.with-underline {
            background: none;
          }
        }
      }
    }
  }
`;
