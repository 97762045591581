import styled from "@emotion/styled";
import Colors from "styles/colors";
import Constants from "../constants";
import { BRAND_SERVICE_PRIMARY_COLOR_NAME, LIGHT_BACKGROUND_PINK } from "styles/color-consts";
const BORDER_BOTTOM = `1px solid ${Colors["dark-marble"]}`;
const MENU_ITEM_TITLE_HEIGHT = `${Constants.MENU_ITEM_TITLE_HEIGHT}px`;
const MENU_ITEM_LINK_HEIGHT = `${Constants.MENU_ITEM_LINK_HEIGHT}px`;
const SECTION_BORDER_BOTTOM = `1px solid ${Colors["riverstone"]}`;
export const StyledLinkWithSectionsMenuComponent = styled.div`
  border-bottom: ${BORDER_BOTTOM};
  overflow: hidden;
  transition: height 400ms ease;
  flex-shrink: 0;

  &.is-open {
    .link-with-menu-title-wrapper {
      .link-with-menu-title-text {
        color: ${Colors["iris"]};
      }

      .link-with-menu-title-icon {
        transform: rotate(180deg);

        path {
          fill: ${Colors["iris"]};
        }
      }
    }
  }

  .link-with-menu-title-wrapper {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    height: ${MENU_ITEM_TITLE_HEIGHT};
    cursor: pointer;

    .link-with-menu-title-text {
      color: ${Colors["text-gray"]};
      margin-right: 8px;
    }

    .link-with-menu-title-icon {
      height: 10px;
      width: 10px;
      transition: transform 400ms ease;
    }
  }

  .link-with-menu-links-wrapper {
    display: flex;
    flex-direction: column;
    transition: height 400ms ease;

    .section-wrapper {
      margin: 0 0 20px 12px;
    }

    .section-link {
      font-size: 0.875rem;
      color: ${Colors.asphalt};
      font-weight: 500;
      border-bottom: ${SECTION_BORDER_BOTTOM};
      padding: 16px 0;
    }

    .header-link {
      height: ${MENU_ITEM_LINK_HEIGHT};
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      color: ${Colors.tuna};
      text-decoration: none;

      .new-badge-component-wrapper {
        margin-left: 8px;
      }

      .mobile-icon {
        margin-right: 16px;
      }

      &.subsection-link {
        height: 48px;
        color: ${Colors.asphalt};
        display: flex;
      }

      .beta-tag {
        font-size: 0.6875rem;
        font-weight: 400;
        color: ${Colors[BRAND_SERVICE_PRIMARY_COLOR_NAME]};
        background-color: ${Colors[LIGHT_BACKGROUND_PINK]};
        border-radius: 12px;
        padding: 1px 5px;
        line-height: normal;
        border-radius: 32px;
        margin-left: 8px;
      }
    }
  }
`;
