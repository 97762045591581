import styled from "@emotion/styled";
import Colors from "styles/colors";
import { BACKGROUND_GRAY_COLOR_NAME, DARK_WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
export const StyledLinkItemComponent = styled.a`
  font-size: 0.875rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  text-decoration: none;
  color: ${Colors["text-gray"]};
  transition: color 200ms ease;
  border-radius: 8px;

  &:hover {
    color: ${Colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
    background-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
  }
`;
