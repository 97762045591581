import styled from "@emotion/styled";
import Colors from "styles/colors";
import { BACKGROUND_GRAY_COLOR_NAME } from "styles/color-consts";
export const StyledDescriptiveLinkBlock = styled.a`
  display: flex;
  flex-direction: row;
  gap: 12px;
  text-decoration: none;
  color: black;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.2s ease-in;
  align-items: center;

  &:hover {
    background-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
  }

  .icon {
    height: 100%;
    object-fit: contain;
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;

    .title {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 0.875rem;
      line-height: 14px;
      font-weight: 600;
    }

    .description {
      font-size: 0.8125rem;
      line-height: 16px;
      font-weight: 400;
      color: ${Colors.asphalt};
    }
  }
`;
