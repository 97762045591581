import styled from "@emotion/styled";
import { TOP_BAR, ALWAYS_ON_TOP } from "constants/z-index";
import Constants from "segments/desktop/header/layouts/components/basic-header/constants";
import Colors from "styles/colors";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH, HEADER_DESKTOP_MENU_NARROW_SCREEN_MAX_WIDTH } from "/constants/vp-sizes";
import { BACKGROUND_GRAY_COLOR_NAME, WHITE_COLOR_NAME } from "styles/color-consts";
import HeaderConstants from "../constants";
const BASIC_HEADER_HEIGHT = `${Constants.BASIC_HEADER_HEIGHT}px`;
const SECONDARY_HEADER_HEIGHT = `${Constants.SECONDARY_HEADER_HEIGHT}px`;
const UNIFIED_HEADER_TRANSITION = `opacity ${HeaderConstants.SECONDARY_HEADER_SCROLL_FULL_TRANSITION_DURATION}ms ease, transform ${HeaderConstants.SECONDARY_HEADER_SCROLL_FULL_TRANSITION_DURATION}ms ease`;
const UNIFIED_HEADER_TRANSITION_LINKS_DISSAPPEAR = `opacity ${HeaderConstants.SECONDARY_HEADER_SCROLL_DISSAPEARING_LINKS_DURATION}ms ease, transform ${HeaderConstants.SECONDARY_HEADER_SCROLL_DISSAPEARING_LINKS_DURATION}ms ease`;
const SUBMENU_TRANSITION = "opacity 50ms ease";
export const StyledBaseHeaderDesktopComponent = styled.header`
  width: 100%;

  .header-create-account-button-wrapper {
    padding-left: 8px;

    .regular-button {
      height: 40px;

      .button-component {
        display: flex;
        height: 100%;
        align-items: center;
        font-size: 0.8125rem;
      }
    }
  }

  .header-prominant-contact-sales-button-wrapper {
    padding-left: 8px;

    .regular-button {
      height: 40px;

      a {
        text-decoration: none;
      }

      .button-component {
        display: flex;
        height: 100%;
        align-items: center;
        font-size: 0.8125rem;
      }
    }
  }

  .header-button-wrapper {
    display: flex;
    align-items: center;
    padding-left: 16px;

    .signup-button {
      height: 40px;
    }
  }

  .signup-popup-wrapper {
    position: fixed;
    z-index: ${ALWAYS_ON_TOP};
    top: ${Constants.BASIC_HEADER_HEIGHT + 12}px;
    left: calc(
      (100vw - ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) / 2 + ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px -
        ${Constants.SIGNUP_POPUP_SCREEN_WIDTH}px - ${Constants.SIGNUP_POPUP_SCREEN_RIGHT_MARGIN}px
    );

    @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}) {
      left: calc(100vw - ${Constants.SIGNUP_POPUP_SCREEN_WIDTH}px - ${Constants.SIGNUP_POPUP_SCREEN_RIGHT_MARGIN}px);
    }
  }

  &.scroll-started {
    .secondary-header-wrapper {
      .secondary-header-desktop-component {
        border-bottom: 1px solid ${Colors.riverstone};
      }
    }
  }

  .products-switcher-wrapper {
    position: fixed;
    z-index: ${ALWAYS_ON_TOP};
    top: ${Constants.BASIC_HEADER_HEIGHT + 12} + "px";
    left: calc(
      (100vw - ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) / 2 + ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px -
        ${Constants.PRODUCTS_SWITCHER_SCREEN_WIDTH}px - ${Constants.PRODUCTS_SWITCHER_SCREEN_RIGHT_MARGIN}px
    );

    @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}) {
      left: calc(
        100vw - ${Constants.PRODUCTS_SWITCHER_SCREEN_WIDTH}px - ${Constants.PRODUCTS_SWITCHER_SCREEN_RIGHT_MARGIN}px
      );
    }
  }

  &.scrolled-threshold {
    .with-secondary-logo {
      .basic-header-content-wrapper {
        .basic-header-content {
          .main-logo-wrapper {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            position: absolute;
          }

          .secondary-logo-wrapper {
            opacity: 1;
            visibility: visible;
            position: static;
            display: flex;
            pointer-events: auto;
          }
        }
      }
    }

    .base-header-desktop-content {
      .left-side-header-links {
        .absolute-main-links {
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
          transform: translateY(-8px);
          transition: ${UNIFIED_HEADER_TRANSITION_LINKS_DISSAPPEAR};
        }

        .absolute-unified-links {
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
          transform: none;
          transition: ${UNIFIED_HEADER_TRANSITION};
        }
      }
    }

    .secondary-header-wrapper {
      pointer-events: none;

      .secondary-header-desktop-component {
        opacity: 0;
        visibility: hidden;

        .logo-wrapper {
          opacity: 0;
          visibility: hidden;
          transform: translateY(-5px);
        }

        .links-wrapper {
          opacity: 0;
          visibility: hidden;
          transform: translateY(-5px);
        }
      }
    }
  }

  .base-header-desktop-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: ${BASIC_HEADER_HEIGHT};

    .left-side-header-links,
    .right-side-header-links {
      height: 100%;
      display: flex;

      .link-item-with-menu-component-wrapper,
      .link-item-component-wrapper {
        display: flex;
        align-items: center;

        &.header-language-picker {
          justify-content: center;
          align-items: center;

          .language-picker-component {
            .selected-language {
              border-radius: 8px;
              padding: 0 16px;

              &:hover {
                background-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
              }
            }

            .earth-icon {
              margin: 0 6px;
              width: 18px;
              height: 18px;
            }

            .arrow-down-icon {
              height: 7px;
            }

            .language-picker-dialog-component-wrapper {
              top: 52px;
            }
          }
        }
      }
    }

    .left-side-header-links {
      flex-grow: 1;
      flex-shrink: 0;

      .absolute-unified-links,
      .absolute-main-links {
        position: absolute;
        display: flex;
        height: 100%;
      }

      .absolute-main-links {
        opacity: 1;
        visibility: visible;
        transition: ${UNIFIED_HEADER_TRANSITION};

        .submenu-enter {
          opacity: 0.01;
        }

        .submenu-enter.submenu-enter-active {
          opacity: 1;
          transition: ${SUBMENU_TRANSITION};
        }

        .submenu-leave {
          opacity: 1;
        }

        .submenu-leave.submenu-leave-active {
          opacity: 0.01;
          transition: ${SUBMENU_TRANSITION};
        }
      }

      .absolute-unified-links {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transform: translateY(8px);
        transition: ${UNIFIED_HEADER_TRANSITION_LINKS_DISSAPPEAR};
      }
    }

    .right-side-header-links {
      flex-shrink: 0;
    }

    .header-button-wrapper {
      flex-shrink: 0;
    }
  }

  .header-menu-component-wrapper {
    position: fixed;
    top: ${BASIC_HEADER_HEIGHT};
    z-index: ${TOP_BAR};
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px); // currently not supported in Firefox
    pointer-events: none;
    width: 100%;
    overflow: auto;

    .menu-component-wrapper {
      pointer-events: all;
    }
  }

  .secondary-header-wrapper {
    height: ${SECONDARY_HEADER_HEIGHT};
    position: fixed;
    width: 100%;
    z-index: ${TOP_BAR - 1};
  }

  .header-menu-component-wrapper.enhanced-products {
    .structured-header-menu-left-panel-items {
      .inner-menu-item {
        .inner-menu-item-subitems {
          max-height: 246px;
          gap: 8px;

          .inner-menu-item-subitem {
            padding: 8px 0;

            .inner-menu-item-subitem-wrapper {
              display: flex;
              width: 100%;

              .inner-menu-item-subitem-title-icon-and-arrow {
                display: flex;

                .inner-menu-item-subitem-title-icon {
                  width: 48px;
                  height: 48px;
                  margin: 0 10px;
                }

                width: 100%;
                align-items: center;

                .inner-menu-item-subitem-title-and-subtitle {
                  display: flex;
                  flex-direction: column;
                  width: 100%;

                  .inner-menu-item-subitem-title {
                    line-height: 24px;
                  }

                  .inner-menu-item-subitem-subtitle {
                    line-height: 24px;
                    color: ${Colors.asphalt};
                    font-size: 0.8125rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: ${HEADER_DESKTOP_MENU_NARROW_SCREEN_MAX_WIDTH}) {
      .structured-header-menu-left-panel-items {
        .inner-menu-item {
          .inner-menu-item-subitems {
            .inner-menu-item-subitem {
              .inner-menu-item-subitem-wrapper {
                .inner-menu-item-subitem-title-icon-and-arrow {
                  .inner-menu-item-subitem-title-icon {
                    width: 32px;
                    height: 32px;
                    margin: 0 6px;
                  }

                  .inner-menu-item-subitem-title-and-subtitle {
                    .inner-menu-item-subitem-title {
                      line-height: 20px;
                    }

                    .inner-menu-item-subitem-subtitle {
                      line-height: 20px;
                      font-size: 12px;
                    }
                  }

                  .inner-menu-item-subitem-title-arrow {
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
