import styled from "@emotion/styled";
import { BACKGROUND_GRAY_COLOR_NAME, WHITE_COLOR_NAME } from "styles/color-consts";
import Colors from "styles/colors";
const HEADER_HEIGHT = 450;
const HEADER_WIDTH = 1040;
export const StyledShortHeaderMenuComponent = styled.div`
  background-color: ${Colors[WHITE_COLOR_NAME]};
  height: ${HEADER_HEIGHT}px;
  width: ${HEADER_WIDTH}px;
  justify-self: center;
  border-radius: 0 0 8px 8px;
	overflow: hidden;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
	display: flex;
	flex-direction: column;

  .menu-component {
    display: flex;
    height: inherit;
    gap: 32px;
	  justify-content: stretch;
    padding: 48px 0 48px 64px;

    .align-to-link {
      margin-left: 8px;
    }

    .section-title {
      font-size: 1.25rem;
      line-height: 32px;
    }
  }
`;
