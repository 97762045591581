import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH } from "constants/vp-sizes";
import { TOP_BAR, TOP_BAR_SHADOW } from "constants/z-index";
import Colors from "styles/colors";
import HeaderConstants from "segments/desktop/header/layouts/base-header/constants";
import { BRAND_BLACK_BACKGROUND_COLOR, WHITE_COLOR_NAME } from "styles/color-consts";
import Constants from "./constants";
const BASIC_HEADER_HEIGHT = `${Constants.BASIC_HEADER_HEIGHT}px`;
const HEADER_CONTENT_HORIZONTAL_PADDING = `${Constants.HEADER_CONTENT_HORIZONTAL_PADDING}px`;
const LOGO_TRANSITION = `opacity ${HeaderConstants.SECONDARY_HEADER_SCROLL_FIRST_TRANSITION_DURATION}ms ease`;
const IPO_POPUP_COLOR = "#ae63e5";
const ELEVATE_POPUP_SHADOW_COLOR = "#595AD480"; // Color with 80% opacity
export const StyledBasicHeaderComponent = styled.div`
  width: 100%;
  height: ${BASIC_HEADER_HEIGHT};

  .basic-header-content-wrapper {
    width: 100%;
    height: ${BASIC_HEADER_HEIGHT};
    position: fixed;
    z-index: ${TOP_BAR + 1};
    background-color: ${Colors[WHITE_COLOR_NAME]};
    display: flex;
    justify-content: center;
    transition: background-color 200ms ease;

    &.with-shadow {
      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 8px;
        box-shadow: 0px 4px 4px rgba(29, 140, 242, 0.08);
        z-index: ${TOP_BAR_SHADOW};
      }
    }

    .basic-header-content {
      max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
      width: 100%;
      position: fixed;
      height: ${BASIC_HEADER_HEIGHT};
      padding-left: ${HEADER_CONTENT_HORIZONTAL_PADDING};
      padding-right: ${HEADER_CONTENT_HORIZONTAL_PADDING};
      z-index: ${TOP_BAR};
      background-color: ${Colors[WHITE_COLOR_NAME]};
      transition: background-color 200ms ease;
      top: 0;

      display: flex;
      align-items: center;

      .base-header-monday-logo-image {
        height: 42px;
        margin-right: 16px;
      }

      .main-logo-wrapper,
      .secondary-logo-wrapper {
        transition: ${LOGO_TRANSITION};
      }

      .main-logo-wrapper {
        .logo-popup {
          height: 100%;
          position: absolute;
          background: ${IPO_POPUP_COLOR};
          border-radius: 80px;
          top: 66px;
          left: 20px;
          height: 60px;
          display: flex;
          align-items: center;
          box-shadow: -4px 4px 30px;
          color: ${IPO_POPUP_COLOR};
          opacity: 1;
          transition: opacity 200ms ease;
          z-index: 1;

          .popup-text {
            font-size: 0.875rem;
            line-height: 120%;
            color: white;
            margin-left: 24px;

            .link-text {
              padding-left: 4px;
              color: white;
              cursor: pointer;
            }

            flex-grow: 1;
          }

          .popup-gif {
            margin-left: 8px;
            width: 53px;
            height: 100%;
            flex-grow: 0;

            .popup-gif-image {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
            }
          }

          &::before {
            content: "";
            top: -18px;
            left: 35px;
            position: absolute;
            border: 0px solid;
            display: block;
            width: 30px;
            height: 26px;
            background-color: transparent;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            transform: rotate(-50deg);
            box-shadow: -12px -5px 0px 0px;
            color: ${IPO_POPUP_COLOR};
            z-index: -1;
          }

          &.elevate {
            background: ${Colors["gradiant-elevate"]};
            color: ${ELEVATE_POPUP_SHADOW_COLOR};

            &::before {
              color: #1f245b;
            }
          }
        }
      }

      .secondary-logo-wrapper {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        position: absolute;
      }
    }
  }

  &.scrolled {
    .basic-header-content-wrapper {
      .basic-header-content {
        .main-logo-wrapper {
          .logo-popup {
            opacity: 0;
          }
        }
      }
    }
  }

  @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}) {
    .basic-header-content-wrapper {
      max-width: 100vw;

      .basic-header-content {
        max-width: 100vw;
      }
    }
  }

  &.dynamic-color:not(:hover) {
    background-color: ${Colors[BRAND_BLACK_BACKGROUND_COLOR]};

    .basic-header-content-wrapper {
      background-color: ${Colors[BRAND_BLACK_BACKGROUND_COLOR]};

      .basic-header-content {
        background-color: ${Colors[BRAND_BLACK_BACKGROUND_COLOR]};

        .left-side-header-links {
          .link-item-with-menu-component {
            color: ${Colors[WHITE_COLOR_NAME]};
            .link-item-with-menu-icon {
              path {
                fill: ${Colors[WHITE_COLOR_NAME]};
              }
            }
          }
        }

        .right-side-header-links {
          a {
            color: ${Colors[WHITE_COLOR_NAME]};
          }

          .button-component {
            color: ${Colors[WHITE_COLOR_NAME]};
          }
        }

        .mobile-menu-right-section {
          .mobile-header-content {
            .hamburger-menu {
              .hamburger-menu-line {
                background-color: ${Colors[WHITE_COLOR_NAME]};
              }
            }
          }
        }
      }
    }
  }
`;
