import styled from "@emotion/styled";
export const StyledResourcesMenuComponent = styled.div`
  margin-right: 32px;
	
	.section {
    display: flex;
    flex-direction: column;
    gap: 32px;
	  width: 256px;

    .links {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
`;
