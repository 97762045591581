import styled from "@emotion/styled";
import { BACKGROUND_GRAY_COLOR_NAME, IRIS_COLOR_NAME, WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import Colors from "styles/colors";
export const StyledActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
  padding: 16px 32px;
  gap: 24px;

  .contact-sales-wrapper {
    .button {
      .secondary-button {
        a {
          background: none;

          .secondary-button-text {
            font-size: 0.875rem;
            line-height: 14px;
            color: black;
          }
        }
      }
    }
  }

  .cta-wrapper {
    display: flex;
    align-items: center;

    .button .signup-button {
      svg {
        height: 10px;
        width: 12px;
      }

      &.ladda-button {
        background-color: transparent;
      }

      font-size: 0.875rem;
      line-height: 14px;
      height: fit-content;
      padding: 0;
      background-color: transparent;
      color: black;

      &:hover {
        background-color: transparent;
        color: ${Colors[WORK_OS_IRIS_COLOR_NAME]};
      }

      .signup-button {
        padding: 0;
        min-width: unset;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
`;
