import { AIIcon, AutomationsIcon, DashboardsIcon, DevIcon, InfrastructureIcon, IntegrationsIcon, ServiceIcon, WmIcon, CRMIcon } from "./icons/index.js";
import { AI_PAGE_LINK, AUTOMATIONS_PAGE_LINK, CRM_MINI_SITE_LINK, DASHBOARDS_PAGE_LINK, INTEGRATIONS_PAGE_LINK, MONDAY_DB_PAGE_LINK, SERVICE_MINI_SITE_LINK, SOFTWARE_MINI_SITE_LINK, WORK_MANAGEMENT_MINI_SITE_LINK } from "constants/links";
import { BetaIcon } from "../components/icons/index.js";
export const PLATFORM_MINI_LINKS = [
    {
        Icon: DashboardsIcon,
        text: "header.tabs.products.dashboardsReporting",
        link: DASHBOARDS_PAGE_LINK
    },
    {
        Icon: IntegrationsIcon,
        text: "header.tabs.products.integrations",
        link: INTEGRATIONS_PAGE_LINK
    },
    {
        Icon: AutomationsIcon,
        text: "header.tabs.products.automations",
        link: AUTOMATIONS_PAGE_LINK
    },
    {
        Icon: AIIcon,
        text: "header.tabs.products.ai",
        link: AI_PAGE_LINK
    },
    {
        Icon: InfrastructureIcon,
        text: "header.tabs.products.ourInfrastructure",
        link: MONDAY_DB_PAGE_LINK
    }
];
export const PRODUCTS_LINKS = [
    {
        Icon: WmIcon,
        title: "header.tabs.products.mondayWorkManagement.title",
        description: "header.tabs.products.workManagement.description",
        link: WORK_MANAGEMENT_MINI_SITE_LINK
    },
    {
        Icon: CRMIcon,
        title: "header.tabs.products.mondaySalesCRM.title",
        description: "header.tabs.products.crm.description",
        link: CRM_MINI_SITE_LINK
    },
    {
        Icon: DevIcon,
        title: "header.tabs.products.mondayDev.title",
        description: "header.tabs.products.dev.description",
        link: SOFTWARE_MINI_SITE_LINK
    },
    {
        Icon: ServiceIcon,
        title: "header.tabs.products.mondayService.title",
        description: "header.tabs.products.dev.service",
        link: SERVICE_MINI_SITE_LINK,
        TitleIcon: BetaIcon
    }
];
